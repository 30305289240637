import { window } from "global";
import { useState, useEffect, useRef } from "react";
import { compile } from "path-to-regexp";
import classNames from "classnames";
import { Link, useParams, useLocation, useRouteMatch } from "react-router-dom";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { Item, Rune } from "@outplayed/tooltips";
import { useChampionProfileContext } from "@ugg/shared/components/Champions/ChampionProfileContext";
import { useOverview } from "@ugg/shared/components/Champions/pages/Overview/overview-helpers";
import { convertObjectToArray, capitalizeMulti, multibuildTransformMap } from "@ugg/shared/utils/multi-build-helpers";
import { KaynFormIds, Multibuilds } from "@ugg/shared/interfaces/champions/overview.interface";

interface MultiBuildsFilterProps {
  onChange?: (multi: string, championName: string, index: number) => void;
}

export function MultiBuildsFilter(props: MultiBuildsFilterProps) {
  const { championId } = useChampionProfileContext();
  const location = useLocation();
  const { path } = useRouteMatch();
  const urlParams = useParams<{ multi: string }>();
  const { getNormalizedChampionName } = getRiotAssetsContext();
  const normalizedChampionName = getNormalizedChampionName(championId);
  const multiRef = useRef<HTMLDivElement>(null);
  const [showAll, setShowAll] = useState(false);
  const { filters, tabData } = useOverview({ ssr: true });

  const tabDataArray = convertObjectToArray(tabData);
  const isKayn = championId === KaynFormIds.DEFAULT;

  const currentMultiBuild = tabDataArray.find(([multi, data]) => {
    if (isKayn) {
      return (
        (!urlParams.multi && multi === String(KaynFormIds.RED)) ||
        (urlParams.multi && multi === String(multibuildTransformMap[urlParams.multi as Multibuilds]))
      );
    }
    return (!urlParams.multi && multi === Multibuilds.RECOMMENDED) || urlParams.multi === multi;
  });

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (multiRef.current && e.target !== null && !multiRef.current.contains(e.target as HTMLElement)) {
        setShowAll(false);
      }
    };

    window?.addEventListener("click", handleOutsideClick);
    return () => window?.removeEventListener("click", handleOutsideClick);
  }, []);

  const multiURL = (multi: string) => {
    const toPath = compile(path);
    let currentMultiParam = multi !== Multibuilds.RECOMMENDED ? multi : undefined;
    if (isKayn) {
      currentMultiParam = multi === String(KaynFormIds.RED) ? Multibuilds.REDKAYN : Multibuilds.BLUEKAYN;
    }
    return {
      pathname: toPath({ ...urlParams, multi: currentMultiParam }),
      search: location.search,
    };
  };

  const formatLabel = (value: (typeof tabDataArray)[number] | undefined) => {
    let label = capitalizeMulti(value?.[0] || "", "Rec.");
    if (isKayn) {
      const key = Object.keys(multibuildTransformMap).find(
        (key) => String(multibuildTransformMap[key as Multibuilds]) === value?.[0],
      );
      label = capitalizeMulti(key || "", "Rec.");
    }
    return (
      <div className="flex-1 flex justify-between gap-[10px]">
        <span>{value && label}</span>
        <div className="image-holder">
          <div className="rune-image-container">
            {value && value[1].keystone !== undefined && <Rune patch={filters.patch} runeId={value[1].keystone} ssr />}
          </div>
          <div className={classNames("item-image-container")}>
            {value && value[1].item > 0 && <Item itemId={value[1].item} ssr />}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classNames("multi-builds-filter-button", { active: showAll })} ref={multiRef}>
      <div className="multi-builds-content" onClick={() => setShowAll(!showAll)}>
        {formatLabel(currentMultiBuild)}
      </div>
      {showAll && (
        <div className="dropdown-multis">
          {tabDataArray.map(([multi, multiData], index) => (
            <Link
              key={multi}
              className={classNames("indv-multi-container", {
                active: multi === currentMultiBuild?.[0],
              })}
              to={multiURL(multi)}
              onClick={() => {
                setShowAll(false);
                let currentMulti = multi;
                if (isKayn) {
                  currentMulti = multi === String(KaynFormIds.RED) ? Multibuilds.REDKAYN : Multibuilds.BLUEKAYN;
                }
                props.onChange?.(currentMulti, normalizedChampionName, index);
              }}
            >
              {formatLabel([multi, multiData])}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}
