import { useQueryString } from "@ugg/shared/hooks/use-query-string";
import { useChampionProfileContext } from "@ugg/shared/components/Champions/ChampionProfileContext";
import { useValidateQueryParams } from "@ugg/shared/query-params/params-helpers";
import { validParams } from "@ugg/shared/query-params/valid-params";

import BuildSEO from "./BuildSEO";
import CountersSEO from "./CountersSEO";

import { CHAMPION_PAGES, SPECIAL_CHAMPION_PAGES } from "@ugg/shared/pages/champion-pages";
import { FilterOption } from "@ugg/shared/interfaces/filter-manager.interface";

export default function SEOFluff(props: { className?: string }) {
  const { championId, page, role } = useChampionProfileContext();
  const { className } = props;

  const queryParams = useQueryString();
  const validateQueryParams = useValidateQueryParams(validParams);
  const validatedQueryParams = validateQueryParams(CHAMPION_PAGES.OVERVIEW, queryParams, true);

  const validPageParams: Record<string, any> = (page && validParams[page]) || {};
  const rankOptions = validPageParams.rank || [];
  const currentRank = rankOptions.find((rank: FilterOption) => rank.value === validatedQueryParams.rank);
  const displayPatch = validatedQueryParams.patch ? validatedQueryParams.patch.replace("_", ".") : "";

  if (page === CHAMPION_PAGES.COUNTERS) {
    return <CountersSEO className={className} patch={validatedQueryParams.patch} displayPatch={displayPatch} />;
  } else if (page === CHAMPION_PAGES.OVERVIEW || (Object.values(SPECIAL_CHAMPION_PAGES) as string[]).includes(page || "")) {
    return <BuildSEO className={className} displayPatch={displayPatch} rank={currentRank && currentRank.label} />;
  }

  return null;
}
