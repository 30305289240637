import { useJSONFetcher, JSONFetcherOptions } from "@outplayed/json-fetcher";
import { useUGGApiVersions } from "../ugg-api-versions";
import { champion_transform_map } from "@ugg/shared/utils/multi-build-helpers";
import { getApiRoot } from "../../api-helpers";
import { useValidateQueryParams } from "@ugg/shared/query-params/params-helpers";
import { validParams } from "@ugg/shared/query-params/valid-params";
import { cleanRankingsData, RankingStatsInfo } from "@ugg/shared/api/data-parser/champions/ranking-stats";
import { CHAMPION_PAGES } from "@ugg/shared/pages/champion-pages";
import { QueueTypeS } from "@ugg/shared/utils/queue-type-helpers";
import { getKey } from "@ugg/shared/api/data-parser/champions/common";
import { RANKINGS_EMERALD_PLUS_WORLD } from "@ugg/shared/constants/constants";
import { RegionS } from "@ugg/shared/utils/region-helpers";
import { RankS } from "@ugg/shared/utils/rank-helpers";

import { RankingStats } from "@ugg/shared/interfaces/champions/ranking-stats.interface";

export function useChampionRankingStatsURL(championId: number, options: { queueType?: string; patch?: string }) {
  let { queueType, patch } = options;
  const { data: version } = useUGGApiVersions({ apiKey: "rankings", patch: options.patch });

  // backend saves to /urf/ url instead of /arurf/
  if (queueType === QueueTypeS.ARURF) {
    queueType = QueueTypeS.URF;
  }

  const root = getApiRoot(options.patch);
  return [`${root}/rankings`, patch, queueType, championId, `${version}.json`].join("/");
}

export function useChampionRankingStats(
  championId: number,
  options?: { params?: Record<string, any> } & JSONFetcherOptions<RankingStats, ReturnType<typeof cleanRankingsData>>,
) {
  const { params = {}, ssr = false, skip = false } = options || {};
  const validateQueryParams = useValidateQueryParams(validParams);
  const validatedQueryParams = validateQueryParams(CHAMPION_PAGES.OVERVIEW, params, true);

  const rankingStatsURL = useChampionRankingStatsURL(championId, validatedQueryParams);
  const recCacheKey = `${RANKINGS_EMERALD_PLUS_WORLD}::${rankingStatsURL}`;
  return useJSONFetcher<RankingStats, ReturnType<typeof cleanRankingsData>>(rankingStatsURL, {
    ssr,
    skip: skip,
    onCompleted: (url, json, cacheKey) => {
      const cleanData = cleanRankingsData(json);

      const dataKey = getKey(validatedQueryParams.region, validatedQueryParams.rank, "");

      if (cacheKey.match(recCacheKey)) {
        return Object.fromEntries(Object.entries(cleanData || {}).filter(([key, value]) => key.match(dataKey)));
      }

      return cleanData;
    },
    customCacheKey:
      validatedQueryParams.region === RegionS.WORLD &&
      validatedQueryParams.rank === RankS.EMERALD_PLUS &&
      validatedQueryParams.queueType === QueueTypeS.RANKED_SOLO
        ? recCacheKey
        : "",
  });
}

export function useMultipleChampionRankingStats(championId: number, options?: { params?: Record<string, any>; ssr?: boolean }) {
  const { params = {}, ssr = false } = options || {};

  const res: Record<
    number,
    {
      rankingStats: Record<string, RankingStatsInfo>;
      fetchingRankingStats: boolean;
      errorRankingStats: Error | null;
    }
  > = {};
  const maxBuilds = Object.values(champion_transform_map).reduce((acc, curr) => {
    return Math.max(curr.length, acc);
  }, 1);
  const baseArray = Array(maxBuilds).fill(0);
  const champions = champion_transform_map[championId]
    ? champion_transform_map[championId].concat(baseArray.slice(champion_transform_map[championId].length))
    : [championId].concat(baseArray.slice(1));

  champions.map((championId) => {
    let {
      data: rankingStats,
      loading: fetchingRankingStats,
      error: errorRankingStats,
    } = useChampionRankingStats(championId, { ssr, params });

    res[championId] = { rankingStats, fetchingRankingStats, errorRankingStats };
  });

  return res;
}
