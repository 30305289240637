import { Switch, Route } from "react-router-dom";
import { ChampionProfile } from "./Champions/ChampionProfile";
import { RouteStatus } from "@ugg/shared/components/common/RouteStatus";
import { Error404 } from "@ugg/shared/components/common/Error404";
import { AppRoutes } from "@ugg/shared/routes/app-routes";
import { SPECIAL_GAME_MODES } from "@ugg/shared/utils/special-game-modes";

export function ChampionsRoutes() {
  const specialGameModeBuildUrls = Object.values(SPECIAL_GAME_MODES).map((mode) =>
    AppRoutes.CHAMPION_PROFILE_BUILD_SPECIAL_GAME_MODE.replace(":gameMode", mode.urlName),
  );

  return (
    <Switch>
      <Route
        path={[...specialGameModeBuildUrls, AppRoutes.CHAMPION_PROFILE_BUILD, AppRoutes.CHAMPION_PROFILE_ROOT]}
        component={ChampionProfile}
      />
      <RouteStatus statusCode={404}>
        <Error404 />
      </RouteStatus>
    </Switch>
  );
}
