import { useEffect, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { alphanumeric } from "@ugg/shared/utils/string";
import { useChampionPrimaryRole } from "@ugg/shared/api/requests/champions/common";
import { useNexusBlitzPrimaryRole } from "@ugg/shared/api/requests/champions/nexus-blitz-primary-role";
import { SPECIAL_CHAMPION_PAGES, useChampionPage } from "@ugg/shared/pages/champion-pages";
import { QueueTypeS } from "@ugg/shared/utils/queue-type-helpers";
import { RoleS } from "@ugg/shared/utils/role-helpers";
import { useQueryString } from "@ugg/shared/hooks/use-query-string";
import { ChampionProfileContextInterface, ChampionProfileContext, initialState } from "./ChampionProfileContext";
import { validParams } from "@ugg/shared/query-params/valid-params";
import { useValidateQueryParams } from "@ugg/shared/query-params/params-helpers";
import { buildQueryParams } from "@ugg/shared/utils/url-query-params";

interface ChampionProfileProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

export function ChampionProfileProvider(props: ChampionProfileProviderProps) {
  const page = useChampionPage();

  const { getChampionIdByName, useChampionIndiv } = getRiotAssetsContext();
  const history = useHistory();
  const location = useLocation();
  const params = useParams<{ champion: string; role: string; multi: string }>();
  const searchParams = useQueryString();
  // Current champion info
  const normalizedChampionName = alphanumeric(params.champion);
  const championId = getChampionIdByName(normalizedChampionName);
  const validateQueryParams = useValidateQueryParams(validParams, true);
  const validatedQueryParams = validateQueryParams(page, searchParams, true);

  let { data: role, loading: loadingRoles } = useChampionPrimaryRole(championId, { ssr: true });

  const isNexusBlitz =
    validatedQueryParams.queueType === QueueTypeS.NEXUS_BLITZ || page === SPECIAL_CHAMPION_PAGES.OVERVIEW_NEXUS_BLITZ;
  const { data: nexusBlitzPrimaryRole, loading: fetchingNexusBlitzPrimaryRole } = useNexusBlitzPrimaryRole(championId, {
    ssr: true,
    skip: !isNexusBlitz,
  });
  const { data: championIndiv, loading: fetchingChampionIndiv } = useChampionIndiv(championId, { ssr: true });

  if (params.role) {
    role = params.role;
  } else if (isNexusBlitz && !fetchingNexusBlitzPrimaryRole && searchParams.role) {
    role = searchParams.role;
  } else if (validatedQueryParams.role) {
    role = validatedQueryParams.role;
  }

  useEffect(() => {
    if (page && !fetchingNexusBlitzPrimaryRole) {
      const pageParams = validParams[page];
      const validNexusBlitzRoles = pageParams.role ?? [];
      const hasValidRole = validNexusBlitzRoles.some((option) => {
        return option.value === searchParams.role;
      });
      if (isNexusBlitz && !hasValidRole) {
        history.replace({
          pathname: location.pathname,
          search: buildQueryParams({ ...searchParams, role: nexusBlitzPrimaryRole || RoleS.LANE }),
        });
      }
    }
  }, [page, fetchingNexusBlitzPrimaryRole, isNexusBlitz, searchParams.role, role]);

  const isLoading = fetchingNexusBlitzPrimaryRole || loadingRoles || fetchingChampionIndiv;
  const isError = !isLoading && (!championIndiv || !page);

  const championProfileInfo: ChampionProfileContextInterface = {
    isLoading,
    isError,
    championData: championIndiv,
    championId: parseInt(championIndiv?.key || "0"),
    role: role || RoleS.MIDDLE, // Default 'mid'
    page: page || initialState.page,
    multi: params.multi,
  };

  const championProfileContextValue = useMemo(() => {
    return championProfileInfo;
  }, [JSON.stringify(championProfileInfo)]);

  return <ChampionProfileContext.Provider value={championProfileContextValue}>{props.children}</ChampionProfileContext.Provider>;
}
