import { useChampionOverview } from "./overview";
import { RoleS } from "@ugg/shared/utils/role-helpers";
import { getOverview } from "../../data-parser/champions/overview";

export function useNexusBlitzPrimaryRole(
  championId: number,
  options?: { forcedParams?: Record<string, string>; ssr?: boolean; skip?: boolean },
) {
  const { forcedParams, ssr, skip } = options || {};
  const params = { params: { ...forcedParams, queueType: "nexus_blitz", region: "world" }, ssr, skip };
  const { data, loading, error, params: overviewParams } = useChampionOverview(championId, params);

  const { queueType, region, rank } = overviewParams;
  const roleMatchCount = { [RoleS.LANE]: 0, [RoleS.JUNGLE]: 0 };
  const roles = Object.keys(roleMatchCount) as Array<keyof typeof roleMatchCount>;
  roles.forEach((role) => {
    const { matches } = getOverview(data, { queueType, region, rank, role }) || {};
    roleMatchCount[role] = matches || 0;
  });
  let primaryRole = Object.entries(roleMatchCount).sort((a, b) => b[1] - a[1])[0][0];

  return { data: data ? primaryRole : null, loading, error };
}
