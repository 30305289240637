import { useEffect } from "react";
import classNames from "classnames";
import { useLocation, useParams } from "react-router-dom";
import { useUserPremiumState } from "@ugg/shared/api/requests/accounts/user-state";
import { MediaQuery } from "@outplayed/responsive";
import { ContentSidePadding } from "components/ContentSidePadding";
import { ChampionProfileProvider } from "@ugg/shared/components/Champions/ChampionProfileProvider";
import { useChampionProfileContext } from "@ugg/shared/components/Champions/ChampionProfileContext";
import { ChampionProfileBackground } from "@ugg/shared/components/Champions/ChampionProfileBackground";
import { ChampionProfileHeader } from "@ugg/shared/components/Champions/ChampionProfileHeader/ChampionProfileHeader";
import { ChampionProfilePageNav } from "@ugg/shared/components/Champions/ChampionProfilePageNav";
import { ChampionProfileFilterManager } from "@ugg/shared/components/Champions/ChampionProfileFilterManager";
import { ChampionProfileRoutes } from "./ChampionProfileRoutes";
import { Error404 } from "@ugg/shared/components/common/Error404";
import { FadeTransitionWrapper } from "@ugg/shared/components/common/FadeTransitionWrapper";
import { RampLeftRail, RampRightRail } from "components/Ads/RampRails";
import { AntiAdblockLeftRail, AntiAdblockRightRail } from "components/Ads/anti-adblock/AntiAdblockRails";
import { useLanguage } from "lib/hooks/languageHooks";
import { useQueryString } from "@ugg/shared/hooks/use-query-string";
import { useValidateQueryParams } from "@ugg/shared/query-params/params-helpers";
import { validParams } from "@ugg/shared/query-params/valid-params";
import { useChampionProfileSEO } from "@ugg/shared/utils/seo-helpers";
import { useAntiAdblock } from "lib/hooks/use-ad-free";

export function ChampionProfile() {
  return (
    <ChampionProfileProvider>
      <ChampionProfileContent />
    </ChampionProfileProvider>
  );
}

function ChampionProfileContent() {
  const location = useLocation<{ scrollTop?: boolean }>();
  const { isLoading, isError, page, championId, championData, role } = useChampionProfileContext();
  const [language, setLanguage] = useLanguage();
  const params = useParams<{ language?: string; multi?: string }>();
  const getChampionProfileSEO = useChampionProfileSEO();
  const queryParams = useQueryString();
  const validateQueryParams = useValidateQueryParams(validParams);
  const validatedQueryParams = validateQueryParams(page, queryParams, true);

  useEffect(() => {
    window && window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (location.state && location.state.scrollTop) {
      window && window.scrollTo(0, 0);
    }
  }, [location.state && location.state.scrollTop]);

  useEffect(() => {
    if (params.language) {
      setLanguage(params.language);
    }
  }, [params.language]);

  if (isLoading) {
    return null;
  }

  if (isError) {
    return <Error404 />;
  }

  const isOverviewPage = page.includes("champion_overview");

  return (
    <RailAdsContainer>
      <div className="flex mx-auto max-md:w-full">
        <ContentSidePadding className="max-md:w-full">
          <div
            className={classNames("relative z-[1] w-[1014px] max-md:w-full mx-auto pt-[38px] max-sm:pt-[24px]", {
              // "max-lg:max-w-[900px]": isOverviewPage, // Temp remove DesktopSmall
            })}
          >
            {getChampionProfileSEO(page, {
              ...(championData && { champion: { name: championData.name, key: championId } }),
              ...(params.multi && { multi: params.multi }),
              patch: validatedQueryParams.patch,
              language: language,
              role,
            })}
            <ChampionProfileBackground />
            <ChampionProfileHeader className="mb-[24px]" />
            <ChampionProfilePageNav />
            <ChampionProfileFilterManager className="my-[24px]" />
            <ChampionProfileRoutes />
          </div>
        </ContentSidePadding>
      </div>
    </RailAdsContainer>
  );
}

function RailAdsContainer(props: { children: React.ReactNode | React.ReactNode[] }) {
  const antiAdBlock = useAntiAdblock();
  const { data } = useUserPremiumState();

  if (antiAdBlock && !data?.isPremium) {
    const utm = {
      utm_source: "UGG",
      utm_campaign: "native1build",
    };

    return (
      <div className={classNames("flex justify-center mx-auto w-full max-md:w-full")}>
        <MediaQuery min="DESKTOP_SMALL" max="DESKTOP_LARGE">
          <div className="flex-1 min-w-0 mr-[-12px]">
            {/* <AntiAdblockLeftRail className="sticky top-[calc(var(--masthead-height)+var(--game-nav-height)+24px)] mt-[283px] items-end" /> */}
          </div>
        </MediaQuery>
        {props.children}
        <MediaQuery min="DESKTOP_SMALL" max="DESKTOP_LARGE">
          <div className="flex-1 min-w-0 ml-[-12px]">
            <AntiAdblockRightRail
              className="sticky top-[calc(var(--masthead-height)+var(--game-nav-height)+24px)] mt-[283px] items-start"
              utm={utm}
            />
          </div>
        </MediaQuery>
      </div>
    );
  }

  return (
    <div className={classNames("flex justify-center mx-auto w-full max-md:w-full")}>
      <MediaQuery min="DESKTOP_SMALL" max="DESKTOP_LARGE">
        <div className="flex-1 min-w-0 mr-[-12px]">
          <RampLeftRail className="sticky top-[calc(var(--masthead-height)+var(--game-nav-height)+24px)]" />
        </div>
      </MediaQuery>
      {props.children}
      <MediaQuery min="DESKTOP_SMALL" max="DESKTOP_LARGE">
        <div className="flex-1 min-w-0 ml-[-12px]">
          <RampRightRail className="sticky top-[calc(var(--masthead-height)+var(--game-nav-height)+24px)]" />
        </div>
      </MediaQuery>
    </div>
  );
}
