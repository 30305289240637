import { useMemo } from "react";
import { compile } from "path-to-regexp";
import { useParams, useRouteMatch, useLocation } from "react-router-dom";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { useQueryString } from "@ugg/shared/hooks/use-query-string";
import { useValidateQueryParams } from "@ugg/shared/query-params/params-helpers";
import { validParams } from "@ugg/shared/query-params/valid-params";
import { useChampionProfileContext } from "@ugg/shared/components/Champions/ChampionProfileContext";
import { champion_transform_map } from "@ugg/shared/utils/multi-build-helpers";
import { useMultipleChampionOverview } from "@ugg/shared/api/requests/champions/overview";
import { useChampionRankingStats } from "@ugg/shared/api/requests/champions/ranking-stats";
import { useAramData, useUrfData } from "@ugg/shared/api/requests/champions/queue-types-balances";
import { OverviewInfo } from "@ugg/shared/api/data-parser/champions/overview";
import { getRankings } from "@ugg/shared/api/data-parser/champions/ranking-stats";
import { QueueTypeS } from "@ugg/shared/utils/queue-type-helpers";
import {
  cleanMultipleOverviewData,
  parseCleanMultipleOverviewData,
  getDefaultChampionTransformId,
  isChampionTransform,
  generateMultipleOverviews,
  generateTabs,
  multibuildTransformMap,
} from "@ugg/shared/utils/multi-build-helpers";
import { Multibuilds } from "@ugg/shared/interfaces/champions/overview.interface";
import { QueueTypeBalances } from "@ugg/shared/interfaces/champions/queue-type-balances.interface";

export function minChampionOverviewData(championOverview: OverviewInfo | null) {
  return !!(
    (
      championOverview &&
      championOverview.rec_runes &&
      championOverview.rec_summoner_spells &&
      championOverview.rec_starting_items &&
      championOverview.rec_core_items &&
      championOverview.rec_skills &&
      championOverview.rec_skill_path
    )
    // championOverview.item_options_1 &&
    // championOverview.item_options_2 &&
    // championOverview.item_options_3
  );
}

export function useOverview({ ssr = false }: { ssr?: boolean } = {}) {
  const { championId, championData, page, role } = useChampionProfileContext();
  const { multi, champion } = useParams<{ multi: string; champion: string }>();
  const { useRiotBootItems } = getRiotAssetsContext();
  const { data: bootItems } = useRiotBootItems({ ssr });

  const queryParams = useQueryString();
  const validateQueryParams = useValidateQueryParams(validParams);
  const validatedQueryParams = validateQueryParams(page, { ...queryParams, role }, true) as {
    queueType: string;
    region: string;
    rank: string;
    role: string;
    patch: string;
    opp?: string;
  };

  // Ranking Stats
  const {
    data: rankingStats,
    loading: fetchingRankingStats,
    error: _errorRankingStats,
  } = useChampionRankingStats(championId, { params: validatedQueryParams, ssr });

  const transformChampionId = multibuildTransformMap[multi as Multibuilds] || getDefaultChampionTransformId(championId);
  const isTransformedChamp = isChampionTransform(transformChampionId);
  // Multi Builds
  const championOverviews = useMultipleChampionOverview(championId, { params: validatedQueryParams, ssr });
  const generatedOverviews = generateMultipleOverviews(championOverviews, championId, validatedQueryParams);
  const cleanedMultipleOverviews = cleanMultipleOverviewData(generatedOverviews, bootItems);
  // console.log("championOverviews,", championOverviews)
  // console.log("generatedOverviews,", generatedOverviews)
  // console.log("cleanedMultipleOverviews,", cleanedMultipleOverviews);

  const { data: aramStats, loading: fetchingAramStats } = useAramData(validatedQueryParams.patch, {
    ssr,
    skip: validatedQueryParams.queueType !== QueueTypeS.ARAM,
  });
  const { data: urfStats, loading: fetchingUrfStats } = useUrfData(validatedQueryParams.patch, {
    ssr,
    skip: validatedQueryParams.queueType !== QueueTypeS.PICK_URF && validatedQueryParams.queueType !== QueueTypeS.ARURF,
  });

  const tracking = isTransformedChamp
    ? championOverviews[champion_transform_map[championId][0]].fetchingChampionOverview ||
      championOverviews[champion_transform_map[championId][1]].fetchingChampionOverview
    : championOverviews[championId].fetchingChampionOverview;

  const tabData = useMemo(() => {
    return generateTabs(cleanedMultipleOverviews, bootItems);
  }, [cleanedMultipleOverviews, tracking, JSON.stringify(validatedQueryParams)]);

  const filteredDefaultChampionOverview = parseCleanMultipleOverviewData(
    cleanedMultipleOverviews || [],
    transformChampionId,
    Multibuilds.RECOMMENDED,
  );
  const filteredChampionOverview = parseCleanMultipleOverviewData(
    cleanedMultipleOverviews || [],
    transformChampionId,
    multi || Multibuilds.RECOMMENDED,
  );
  const filteredRankingStats = getRankings(rankingStats, validatedQueryParams);
  const fetchingData = fetchingRankingStats || tracking || fetchingAramStats || fetchingUrfStats;

  const championModifications: { [key: string]: QueueTypeBalances | undefined } = {
    [QueueTypeS.ARAM]: aramStats,
    [QueueTypeS.PICK_URF]: urfStats,
    [QueueTypeS.ARURF]: urfStats,
  };
  const queueTypeBalancesKey = (championData?.name || "").replace(/[\.' ]/g, "").toLowerCase();

  return {
    filters: validatedQueryParams,
    fetchingData,
    defaultChampionOverview: filteredDefaultChampionOverview || null,
    championOverview: filteredChampionOverview || null,
    rankingStats: filteredRankingStats,
    championModifications: championModifications?.[validatedQueryParams.queueType]?.[queueTypeBalancesKey],
    tabData,
  };
}

export function useBuildTabsRedirect(championOverview: OverviewInfo | null) {
  const location = useLocation();
  const { championId } = useChampionProfileContext();
  const urlParams = useParams<{ language: string; champion: string; role: string; multi: string; gameMode: string }>();
  const { path } = useRouteMatch();
  // const [redirectUrl, setRedirectUrl] = useState<{ pathname: string; search: string } | null>(null);
  let redirectUrl: { pathname: string; search: string } | null = null;

  let redirectToRec = false;
  if (urlParams?.multi && urlParams?.multi !== Multibuilds.RECOMMENDED) {
    if (championId in champion_transform_map) {
      redirectToRec = !Object.entries(multibuildTransformMap).some(([multi, transformChampionId]) => {
        return urlParams?.multi === multi;
      });
    } else if (!minChampionOverviewData(championOverview)) {
      redirectToRec = true;
    }
  }
  if (redirectToRec) {
    const toPath = compile(path);

    redirectUrl = {
      pathname: toPath({ ...urlParams, multi: undefined }),
      search: location.search,
    };
    // setRedirectUrl({
    //   pathname: toPath({ ...urlParams, multi: undefined }),
    //   search: location.search,
    // });
  } else {
    redirectUrl = null;
    // setRedirectUrl(null);
  }

  return redirectUrl;
}
