import { window } from "global";
import { useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { compile } from "path-to-regexp";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { useChampionProfileContext } from "@ugg/shared/components/Champions/ChampionProfileContext";
import { PatchOption } from "@ugg/shared/interfaces/filter-manager.interface";
import { validParams } from "@ugg/shared/query-params/valid-params";
import { getValidParamKeys } from "@ugg/shared/query-params/params-helpers";
import { MediaQuery } from "@outplayed/responsive";
import { QueueTypeS } from "@ugg/shared/utils/queue-type-helpers";
import { ReactComponent as FilterIcon } from "@ugg/shared/assets/svg/filters-icon.svg";

import { useFilterManager, FilterManagerWrapper } from "@ugg/shared/components/FilterManager/FilterManager";
import { usePatchOptions } from "@ugg/shared/components/FilterManager/filters/usePatchOptions";
import Role from "@ugg/shared/components/FilterManager/filters/Role";
import RoleSplit from "@ugg/shared/components/FilterManager/filters/RoleSplit";
import ChampionOverviewRoleSplit from "@ugg/shared/components/FilterManager/filters/ChampionOverviewRoleSplit";
import Rank from "@ugg/shared/components/FilterManager/filters/Rank";
import QueueType from "@ugg/shared/components/FilterManager/filters/QueueType";
import Region from "@ugg/shared/components/FilterManager/filters/Region";
import Patch from "@ugg/shared/components/FilterManager/filters/Patch";
import VSChampionFilter, { VSChampionFilterProps } from "@ugg/shared/components/FilterManager/filters/VSChampionFilter";
import ShowAll from "@ugg/shared/components/FilterManager/filters/ShowAll";
import { MoreLessButton } from "@ugg/shared/components/FilterManager/filters/MoreLessButton";
import { MultiBuildsFilter } from "@ugg/shared/components/FilterManager/filters/MultiBuildsFilter";
import { CHAMPION_PAGES, SPECIAL_CHAMPION_PAGES } from "@ugg/shared/pages/champion-pages";
import { capitalizeMulti } from "@ugg/shared/utils/multi-build-helpers";
import { Multibuilds } from "@ugg/shared/interfaces/champions/overview.interface";

export function ChampionProfileFilterManager({ className }: { className?: string }) {
  const { getChampionIdByName } = getRiotAssetsContext();
  const { page, role } = useChampionProfileContext();
  const [showAll, setShowAll] = useState(false);
  const match = useRouteMatch();
  const params = useParams<{ champion: string; multi?: string; language?: string; role?: string }>();
  const filterManagerRes = useFilterManager(page, validParams, { pathParameters: params });
  const { filters, getNewFilters, updateQueryParams } = filterManagerRes;

  const validParamKeys = getValidParamKeys(validParams, page);
  const options = validParams[page];
  const patchFilterOption = ("patch" in options ? options.patch : { options: "latest", max: 5 }) as PatchOption;
  const patchOptions = usePatchOptions(patchFilterOption, filters.patch, options.default.patch);

  const hasRank = [QueueTypeS.RANKED_SOLO, QueueTypeS.RANKED_FLEX].includes(filters.queueType);

  const onOppChange: VSChampionFilterProps["onChange"] = (value) => {
    let { filters, path } = getNewFilters("opp", value === null ? null : { value: value.normalizedName, label: " " });
    const toPath = compile(match.path, { encode: encodeURIComponent, validate: false });
    path = toPath({ ...params, multi: undefined });
    updateQueryParams(filters, path);
  };

  const onMultiBuildChange = (multi: string, championName: string, index: number) => {
    if (process.env.UGG_PUBLIC_PLATFORM !== "app") {
      const build = multi === Multibuilds.RECOMMENDED ? "Recommended" : capitalizeMulti(multi);
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "multibuild-filter-select",
          category: "filter",
          filterType: "multibuild",
          filterValue: build + "-" + championName + "-filter-" + (index + 1),
        });
      }
    }
  };

  const showMultiBuilds = [CHAMPION_PAGES.OVERVIEW, SPECIAL_CHAMPION_PAGES.OVERVIEW_ARAM].includes(page);
  const showMoreLessButton = page === CHAMPION_PAGES.OVERVIEW && process.env.UGG_PUBLIC_PLATFORM !== "app";

  return (
    <FilterManagerWrapper className={className}>
      <div className="filter-manager_label">
        <FilterIcon />
        <MediaQuery min="TABLET" max="DESKTOP_LARGE">
          <span>Filters</span>
        </MediaQuery>
      </div>
      <div className="flex-1 flex items-center flex-wrap gap-[12px]">
        {showMultiBuilds && process.env.UGG_PUBLIC_PLATFORM !== "app" && (
          <>
            <MultiBuildsFilter onChange={onMultiBuildChange} />
            <div className="w-[1px] h-[36px] bg-accent-purple-700" />
          </>
        )}
        {validParamKeys.role && (
          <>
            <MediaQuery min="MOBILE_SMALL" max="DESKTOP_SMALL">
              <Role {...filterManagerRes} role={role} options={"role" in options && options.role ? options.role : []} />
            </MediaQuery>
            <MediaQuery min="DESKTOP_MEDIUM" max="DESKTOP_LARGE">
              {page === CHAMPION_PAGES.OVERVIEW ? (
                <ChampionOverviewRoleSplit {...filterManagerRes} role={role} />
              ) : (
                <RoleSplit {...filterManagerRes} role={role} options={"role" in options && options.role ? options.role : []} />
              )}
            </MediaQuery>
          </>
        )}
        {validParamKeys.rank && hasRank && <Rank {...filterManagerRes} options={options.rank ?? []} />}
        {validParamKeys.opp && hasRank && <VSChampionFilter value={getChampionIdByName(filters.opp)} onChange={onOppChange} />}
        {(showAll || !showMoreLessButton) && (
          <>
            {validParamKeys.queueType && <QueueType {...filterManagerRes} options={options.queueType ?? []} />}
            {validParamKeys.patch && (
              <Patch {...filterManagerRes} options={patchOptions.options} isDefaultPatch={patchOptions.isDefaultPatch} />
            )}
            {validParamKeys.region && <Region {...filterManagerRes} options={options.region ?? []} />}
            {validParamKeys.allChampions && <ShowAll {...filterManagerRes} />}
          </>
        )}
        {showMoreLessButton && <MoreLessButton collapsed={!showAll} onClick={() => setShowAll(!showAll)} />}
      </div>
    </FilterManagerWrapper>
  );
}
