import { useState, useEffect } from "react";
import classNames from "classnames";
import { TooltipContainer } from "@outplayed/tooltips";
import { getTierListPickRateCutoff } from "@ugg/shared/utils/tier-helpers";
import { FilterSelect } from "@ugg/shared/interfaces/filter-manager.interface";

interface ShowAllProps extends FilterSelect {}

export default function ShowAll(props: ShowAllProps) {
  const { filters, onFilterChange } = props;
  const [showAll, setShowAll] = useState(filters.allChampions === "true" ? true : false);

  const onChangeHandler = () => {
    setShowAll(!showAll);
  };

  useEffect(() => {
    if (filters.allChampions !== `${showAll}`) {
      onFilterChange("allChampions", {
        value: `${showAll}`,
        label: `${showAll}`,
      });
    }
  }, [filters.allChampions, showAll]);

  const checkboxWidth = 16;
  const rotatedCheckmarkDim = checkboxWidth - checkboxWidth / 5;
  const checkmarkHeight = (2 * rotatedCheckmarkDim) / (3 * Math.sin(45));
  const checkmarkWidth = checkmarkHeight / 2;
  const checkmarkThickness = checkmarkHeight / 5;

  const tooltipInfo = (
    <div className="w-[260px]">
      This option allows you to display champions with pick rates less than {getTierListPickRateCutoff()}%.
    </div>
  );

  return (
    <TooltipContainer tooltipInfo={tooltipInfo}>
      <div
        className={classNames("filter-select flex items-center px-[12px] text-[14px] font-bold group", {
          active: showAll,
        })}
        onClick={onChangeHandler}
      >
        <div
          className={classNames("relative flex-none mr-[6px] rounded-[3px] bg-lavender-500 group-hover:!bg-purple-100", {
            "!bg-purple-100": showAll,
          })}
          style={{ width: checkboxWidth, height: checkboxWidth }}
        >
          <div
            className="absolute top-0 right-0 translate-x-[-50%] translate-y-[53%] rotate-[45deg] origin-top-right"
            style={{
              visibility: showAll ? "visible" : "hidden",
              width: checkmarkWidth,
              height: checkmarkHeight,
              borderRightWidth: checkmarkThickness,
              borderBottomWidth: checkmarkThickness,
              borderColor: "#FFFFFF",
            }}
          />
        </div>
        <span className="select-none">Show All</span>
      </div>
    </TooltipContainer>
  );
}
